import { isToday } from './utils'

export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

export const title = (value, replacer = ' ') => {
	if (!value) return ''
	const str = value.toString()

	const arr = str.split(replacer)
	const capitalizedArray = []
	arr.forEach(word => {
		const capitalized = word.charAt(0).toUpperCase() + word.slice(1)
		capitalizedArray.push(capitalized)
	})
	return capitalizedArray.join(' ')
}

export const avatarText = value => {
	if (!value) return ''
	const nameArray = value.split(' ')
	return nameArray.map(word => word.charAt(0).toUpperCase()).join('')
}

export const resolveCurrency = value => {
	if (value == 1) return 'VND'
	return 'USD'
}

export const currencyOptions = [
	{ value: 1, label: 'VND' },
	{ value: 2, label: 'USD' },
]

/**
 * Format and return date in Humanize format
 * Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 * Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
	if (!value) return value
	return new Intl.DateTimeFormat('vi-VN', formatting).format(new Date(value))
}

/**
 * Return short human friendly month representation of date
 * Can also convert date to only time if date is of today (Better UX)
 * @param {String} value date to format
 * @param {Boolean} toTimeForCurrentDay Shall convert to time if day is today/current
 */
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
	const date = new Date(value)
	let formatting = { month: 'short', day: 'numeric' }

	if (toTimeForCurrentDay && isToday(date)) {
		formatting = { hour: 'numeric', minute: 'numeric' }
	}

	return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// Strip all the tags from markup and return plain text
export const filterTags = value => value.replace(/<\/?[^>]+(>|$)/g, '')

export const paginateArray = (array, perPage, page) => array.slice((page - 1) * perPage, page * perPage)

export const sortCompare = key => (a, b) => {
	const fieldA = a[key]
	const fieldB = b[key]

	let comparison = 0
	if (fieldA > fieldB) {
		comparison = 1
	} else if (fieldA < fieldB) {
		comparison = -1
	}
	return comparison
}

/* eslint-disable */
export const numberFormat = (number, decimals, dec_point, thousands_point) => {

    if (number == null || !isFinite(number)) {
        // throw new TypeError("number is not valid");
    }

    if (!decimals) {
        var len = number.toString().split('.').length;
        decimals = len > 1 ? len : 0;
    }

    if (!dec_point) {
        dec_point = '.';
    }

    if (!thousands_point) {
        thousands_point = ',';
    }

    number = parseFloat(number).toFixed(decimals);

    number = number.replace(".", dec_point);

    var splitNum = number.split(dec_point);
    splitNum[0] = splitNum[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_point);
    number = splitNum.join(dec_point);

    return number.replace('.00', '');
}

export const formatDateTime = (date) => date.replace("T", " ");
export const formatTimeIso = (date) => date.toString().slice(0, -5);
export const formatRemoveTime = (date) => date.replace("T00:00:00", "");
export const formatDateString = (dateString) => {
	const dateObject = new Date(dateString);
  
	const year = dateObject.getFullYear();
	const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
	const day = dateObject.getDate().toString().padStart(2, "0");
  
	const formattedDate = `${year}-${month}-${day}`;
  
	return formattedDate;
  }

export const hiddenEmail = (email) => {
	const array = email.split("@")
	const str = array[0].substr(1)
	const replace = email.replace(str, "******")
	return replace
}

export const resolveVBank = (account, code) => {
	const lastFourDigits = account.substr(-4)
	return code + '-' + lastFourDigits
}